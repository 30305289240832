import Home from "./Components/Home";
import Portfolio from "./Components/Portfolio";
import "./styles/App.css";
import { useState } from "react";
import Socials from "./Components/Socials";

export default function App() {
  const [hovered, setHovered] = useState(false);

  return (
      <div className="App">
        <Socials />
        <main className="content">
          <Home hovered={hovered} setHovered={setHovered} />
          <Portfolio />
        </main>
      </div>
  );
}
